import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from "./palette"
import MainNavigator from './components/Navigator/Navigator';
import { NotificationProvider } from "./context/NotificationProvider";
import { AuthContextProvider } from './context/AuthContextProvider';
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <CssBaseline />
        <BrowserRouter>
          <AuthContextProvider>
            <MainNavigator />
          </AuthContextProvider>
        </BrowserRouter>
      </NotificationProvider>
    </ThemeProvider>
  </React.StrictMode >
);
