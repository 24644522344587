import React, { useState, useEffect, memo } from "react";
import { Button } from '@mui/material';
import { CardContent, Box, Typography, Divider } from '@mui/material';
import { CustomCard } from "../common/StyledComponents";
import StatisticPanel from "../Statistics/StatisticPanel";
import { isDownlink, isUplink, STATISTIC_MIN_LIMIT } from "../../constants/types.constant";
import { getMissedUplinks, getMissedDownlinks, getFrequencies, getUsedDRMap, getRegionByPacket, sortPackets, getAverageValue, removeUplinkDuplicates } from "../Worker/statistics";
import Papa from "papaparse";

const ManualStatistics = memo(() => {

    const [packets, setPackets] = useState(null);
    const [lastJoinAccept, setLastJoinAccept] = useState(undefined);

    const [uplinkList, setUplinkList] = useState([]);
    const [uniqueUplinkList, setUniqueUplinkList] = useState([]);
    const [uplinkFrequencies, setUplinkFrequencies] = useState([]);
    const [missedUplinks, setMissedUplinks] = useState({});
    const [downlinkList, setDownlinkList] = useState([]);
    const [downlinkFrequencies, setDownlinkFrequencies] = useState([]);
    const [missedDownlinks, setMissedDownlinks] = useState({});
    const [region, setRegion] = useState(null);
    const [usedDRMap, setUsedDRMap] = useState({});

    useEffect(() => {
        if (packets) {
            setUplinkList(packets.filter(isUplink));
            setDownlinkList(packets.filter(isDownlink));
            setMissedDownlinks(getMissedDownlinks(sortPackets(packets)));
            setRegion(getRegionByPacket(packets[0]));
        }
    }, [packets])

    useEffect(() => {
        if (uplinkList) {
            setUniqueUplinkList(removeUplinkDuplicates(uplinkList));
            setUsedDRMap(getUsedDRMap(region, uplinkList));
            setUplinkFrequencies(getFrequencies(uplinkList));
        }
    }, [uplinkList])

    useEffect(() => {
        if (uplinkList) {
            setMissedUplinks(getMissedUplinks(uniqueUplinkList));
        }
    }, [uniqueUplinkList])


    useEffect(() => {
        if (downlinkList) {
            setDownlinkFrequencies(getFrequencies(downlinkList));
        }
    }, [downlinkList])

    const getAverageRSSI = (limit) => getAverageValue(limit ? uplinkList.slice(0, limit) : uplinkList, "rssi");
    const getAverageSNR = (limit) => getAverageValue(limit ? uplinkList.slice(0, limit) : uplinkList, "loRaSNR");

    const loadPackets = (event) => {
        let file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: function (results) {
                let packets = results.data;
                console.log(packets)
                setPackets(packets)
                setLastJoinAccept(packets[packets.length - 1]);
            },
        });
    }

    const clearInputField = (event) => {
        event.target.value = ''
    }

    const clearStatistics = () => {
        setPackets(null);
        setLastJoinAccept(null);
    }

    return (
        <>
            <CustomCard sx={{ overflow: "auto" }}>
                <CardContent sx={{ px: 4, py: 2 }}>
                    <Typography sx={{ mb: 2 }}>Please upload your .csv file with packets to see statistics for this data.</Typography>
                    <Box>
                        <Button variant="contained" component="label" >
                            Upload File
                            <input type="file" hidden onChange={loadPackets} onClick={clearInputField} />
                        </Button>
                        <Button variant="outlined" component="label" onClick={clearStatistics} sx={{ ml: 3 }}>
                            Clear statistics
                        </Button>
                    </Box>
                    <Divider sx={{ mt: 2 }} />
                    {packets
                        &&
                        <StatisticPanel time={new Date(lastJoinAccept.ts).toLocaleString()}
                            packetsCounter={{
                                downlinks: downlinkList.length,
                                uplinks: uplinkList.length,
                                uniqueUplinks: uniqueUplinkList.length,
                                total: packets.length,
                                uplinkFrequencies: uplinkFrequencies.length,
                                downlinkFrequencies: downlinkFrequencies.length
                            }}
                            averageData={{
                                RSSI: getAverageRSSI(),
                                limitRSSI: getAverageRSSI(STATISTIC_MIN_LIMIT),
                                SNR: getAverageSNR(),
                                limitSNR: getAverageSNR(STATISTIC_MIN_LIMIT)
                            }}
                            usedDRMap={usedDRMap}
                            missedUplinks={missedUplinks}
                            missedDownlinks={missedDownlinks}
                            region={region} />
                    }
                </CardContent>
            </CustomCard>
        </>)
});

export default ManualStatistics;