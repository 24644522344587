import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import LoginPage from "../LoginPage/LoginPage";
import NotFoundPage from "../NotFoundPage";
import NSDecoder from '../Tabs/NSDecoder';
import ManualDecoder from '../Tabs/ManualDecoder';
import PrivateRoute from './PrivateRoute';

export default function Navigator() {

    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path="/sign-in" element={<LoginPage />} />
                <Route path="/" element={<LoginPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route element={<PrivateRoute redirectTo="/sign-in" errorMessage="You are not Authorised!" />}>
                <Route element={<MainLayout logBtn={"Log out"} />}>
                    <Route path="/ns" element={<NSDecoder />} />
                </Route>
            </Route>
            <Route element={<MainLayout logBtn={"Log In"} />}>
                <Route path="/manual" element={<ManualDecoder />} />
            </Route>
        </Routes>
    )
}
