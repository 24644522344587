import { MESSAGE_TYPES, MAC_COMMANDS, isDownlink, isUplink, STATISTIC_MIN_LIMIT } from "../../constants/types.constant";
import { UPLIINK_DATA_RATE_TABLE, findRegionByPacketFrequency } from "../../constants/region.constant";
import lora from "lora-packet";

export const sortPackets = (packets) => {
    return packets.sort((a, b) => {
        if (a.ts === b.ts) {
            if (isDownlink(a) && isUplink(b)) return -1;
            if (isUplink(a) && isDownlink(b)) return 1;
            if ((isUplink(a) && isUplink(b)) || ((isDownlink(a) && isDownlink(b)))) return a.index < b.index;
            return 0;
        } else {
            return a.ts < b.ts ? 1 : -1
        }
    })
}

export const getMissedUplinks = (data) => {
    let allMissed = 0;
    let limitMissed = 0;
    if (data.length > 0) {
        let lastUplinkCount = data[0].fcntUp;
        for (let i = lastUplinkCount, j = 0; i >= 0 && j < data.length; i--) {
            if (data[j].fcntUp !== i) {
                allMissed++;
                if (j < STATISTIC_MIN_LIMIT) { limitMissed++ }
            } else {
                j++;
            }
        }
    }
    return { sinceJoin: allMissed, limitPacket: limitMissed }
}

const asHexString = (buffer) => buffer.toString("hex").toUpperCase();

function reverseBuffer(buffer) {
    const reversedBuffer = Buffer.from(buffer);
    return reversedBuffer.reverse();
}

const defineMACCommandKind = (payload) => {
    let command;
    if (asHexString(payload.FOpts)) {
        command = payload.FOpts;
    } else if (asHexString(payload.FPort) === "0" && asHexString(payload.FRMPayload)) {
        command = payload.FRMPayload;
    }
    if (command) {
        let cid = asHexString(reverseBuffer(command.slice(0, 1)));
        return MAC_COMMANDS[cid];
    }
    return null;
}

const isExpectedResponse = (packet, expectedType, expectedResponse) => {
    let payloadData = lora.fromWire(Buffer.from(packet.rawPayload, 'base64'))
    let exactlyCommand = defineMACCommandKind(payloadData);
    if (exactlyCommand) {
        return packet.messageType === expectedType && exactlyCommand.name === expectedResponse;
    }
    return null;
}

function isADRACKReq(packet) {
    let payloadData = lora.fromWire(Buffer.from(packet.rawPayload, 'base64'));
    return payloadData.getFCtrlADRACKReq();
}

export const getMissedDownlinks = (packets) => {
    let missedMac = 0;
    let allMac = 0;
    let failedADRACK = 0;
    let allADRACK = 0;
    for (let i = packets.length - 1; i > 0; i--) {
        switch (packets[i].messageType) {
            case MESSAGE_TYPES.DOWNLINK:
                let payloadData = lora.fromWire(Buffer.from(packets[i].rawPayload, 'base64'))
                let macCommand = defineMACCommandKind(payloadData);
                if (macCommand) {
                    if (!isExpectedResponse(packets[i - 1], macCommand.answerType, macCommand.name)) {
                        missedMac++;
                    }
                    allMac++;
                }
                break;
            case MESSAGE_TYPES.UPLINK:
                if (isADRACKReq(packets[i])) {
                    allADRACK++;
                    if (!isDownlink(packets[i - 1]) || !packets[i - 2] || isADRACKReq(packets[i - 2])) {
                        failedADRACK++;
                    }
                }
                break;
            default:
                break
        }
    }
    return {
        allMac: allMac,
        missedMac: missedMac,
        allADRACK: allADRACK,
        failedADRACK: failedADRACK,
        allMissedDownkinks: missedMac + failedADRACK,
        allCheckedDownlinks: allMac + allADRACK
    }
}

export function getFrequencies(packets) {
    let freq = [];
    for (let i = 0; i < packets.length; i++) {
        let currentFreq = packets[i].frequency;
        if (!freq.includes(currentFreq)) {
            freq.push(currentFreq);
        }
    }
    return freq;
}

export function getRegionByPacket(packet) {
    if (packet) {
        const region = findRegionByPacketFrequency(packet.frequency);
        return region;
    }
}

const calculateDR = (region, packet) => {
    if (region) {
        let dr = UPLIINK_DATA_RATE_TABLE[region]["SF" + packet["spreadFactor"]][packet["bandwidth"]];
        return "DR" + dr;
    }
}

export const getUsedDRMap = (region, data) => {
    let map = {};
    let limitMap = {};
    if (region && data) {
        data.forEach((uplink, i) => {
            let dr = calculateDR(region, uplink);
            dr = dr ? dr : "Unknown";
            let count = map[dr] || 0;
            map[dr] = count + 1;
            if (i < STATISTIC_MIN_LIMIT) { limitMap[dr] = count + 1; }
        })
    }
    return { sinceJoin: map, limitPacket: limitMap }
}

const roundNumber = (num) => Math.round(num * 100) / 100;

export const getAverageValue = (data, key) => {
    const sum = data.reduce((accumulator, packet) => accumulator + packet[key], 0);
    const length = data.length;
    return roundNumber(sum / length);
}

export const removeUplinkDuplicates = (uplinkList) => uplinkList.filter((p, i) => uplinkList.findIndex((pp) => p.fcntUp === pp.fcntUp && p.messageType === pp.messageType) === i);

export const getMostUsedDR = (map) => map && Object.keys(map).sort(function (a, b) { return map[b] - map[a] })[0];