import { createContext } from 'react';

export const STATES = {
    AUTHORIZED: 'AUTHORIZED',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED'
};

export const USER_SCOPES = {
    CUSTOMER: 'customer',
    SUBCUSTOMER: 'subCustomer',
    PROVIDER: 'provider',
    SYSTEM: 'system'
};

export const USER_ROLES = {
    "SYS_ADMIN": {
        scope: USER_SCOPES.SYSTEM,
        access: 'admin'
    },
    "SYS_USER": {
        scope: USER_SCOPES.SYSTEM,
        access: 'user'
    },
    "PROVIDER_ADMIN": {
        scope: USER_SCOPES.PROVIDER,
        access: 'admin'
    },
    "PROVIDER_USER": {
        scope: USER_SCOPES.PROVIDER,
        access: 'user'
    },
    "CUSTOMER_ADMIN": {
        scope: USER_SCOPES.CUSTOMER,
        access: 'admin'
    },
    "CUSTOMER_USER": {
        scope: USER_SCOPES.CUSTOMER,
        access: 'user'
    },
    "SUB_CUSTOMER_ADMIN": {
        scope: USER_SCOPES.SUBCUSTOMER,
        access: 'admin'
    },
    "SUB_CUSTOMER_USER": {
        scope: USER_SCOPES.SUBCUSTOMER,
        access: 'user'
    }
};

const AuthContext = createContext({
    state: null,
    userInfo: null,
    setToAuthorized: () => { },
    setToNotAuthorized: () => { }
});

export default AuthContext;