// Do not decode anything, just put it into AS as is



module.exports = { decodeUplink: decodeUplink }
function decodeUplink(input) {

    var bytes = convertToUint8Array(input.bytes);
    var port = input.fPort;

    var arr = [];
    for (var i = 0; i < bytes.length; ++i) {
        arr.push(bytes[i]);
    }

    // Converts value to unsigned
    function to_uint(x) {
        return x >>> 0;
    }

    function convertToUint8Array(byteArray) {
        var arr = [];
        for (var i = 0; i < byteArray.length; i++) {
            arr.push(to_uint(byteArray[i]) & 0xff);
        }
        return arr;
    }

    return { "bytes": JSON.stringify(arr), 
             "port": port, 
             "payload length": bytes.length };

}
