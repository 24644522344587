import React, { useContext } from "react";
import { Alert, Snackbar } from '@mui/material';
import NotificationContext from "../../context/NotificationContext"

const Notification = () => {

    const notificationCtx = useContext(NotificationContext);
    const closeNotification = () => notificationCtx.clear();

    return (
        notificationCtx.notification !== null &&
        <Snackbar
            open={Boolean(notificationCtx.notificationText)}
            autoHideDuration={6000}
            onClose={closeNotification}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert
                onClose={closeNotification}
                severity={notificationCtx.notification} sx={{ width: '100%' }}>
                {notificationCtx.notificationText}
            </Alert>
        </Snackbar>
    );
};

export default Notification;