export const sortArrayOfObjects = (array, field) => {
    return array.sort((a, b) => a[field].localeCompare(b[field]))
}

export function checkvalidHex(hex) {
    try {
        var hexRegex = /^[0-9A-F]+$/i;
        hex = hex.replace(/\s/g, '');
        hex = hex.replace(/,/g, '');
        if (hexRegex.test(hex)) {
            return hex;
        } else {
            return "";
        }
    } catch (error) {
        console.warn(error);
        return "";
    }
}

//Check if the string is a valid base64 string and return the hex value
export function checkvalidBase64(base) {
    try {
        const Buffer = require('buffer').Buffer;
        var baseRegex = /^[A-Za-z0-9+/=]+$/i;
        base = base.replace(/\s/g, '');
        if (baseRegex.test(base)) {
            return Buffer.from(base, 'base64').toString('hex')
        } else {
            return "";
        }
    } catch (error) {
        console.warn(error);
        return "";
    }
}

//Check if the string is a valid hex or base64 string and return the base64 value
export function checkvalidHexOrBase64(hexOrBase, isHex) {
    try {
        if (isHex) {
            var hexRegex = /^[0-9A-F]+$/i;
            hexOrBase = hexOrBase.replace(/\s/g, '');
            hexOrBase = hexOrBase.replaceAll(",", "");
            if (hexRegex.test(hexOrBase)) {
                let base = Buffer.from(hexOrBase, 'hex').toString('base64');
                return base;
            }
        } else {
            var baseRegex = /^[A-Za-z0-9+/=]+$/i;
            hexOrBase = hexOrBase.replace(/\s/g, '');
            if (baseRegex.test(hexOrBase)) {
                return hexOrBase;
            }
        }
    } catch (error) {
        console.warn(error);
        return "";
    }
}

function to_uint(x) {
    return x >>> 0;
}

export function convertToUint8Array(byteArray) {
    var arr = [];
    for (var i = 0; i < byteArray.length; i++) {
        arr.push(to_uint(byteArray[i]) & 0xff);
    }
    return arr;
}

export function hexStringToBytes(hexString) {
    const string = hexString.split("")
    let byte_array = []
    while (string.length > 0) {
        byte_array = [...byte_array, parseInt("0x" + (string.splice(0, 2)).join(""))]
    }
    return byte_array
}

