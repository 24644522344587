import React, { useState, useEffect } from 'react';
import AuthContext, { STATES, USER_ROLES } from './AuthContext';
import jwt from 'jwt-decode';

function getInitialState() {
    const status = localStorage.getItem('host')
    return status ? STATES.AUTHORIZED : STATES.NOT_AUTHORIZED
}

export const AuthContextProvider = ({ children }) => {
    const [state, setState] = useState(getInitialState);
    const [userInfo, setUserInfo] = useState(null);

    const setToAuthorized = () => {
        setState(STATES.AUTHORIZED);
    };

    const setToNotAuthorized = () => {
        setState(STATES.NOT_AUTHORIZED);
    };

    const getUserInfo = () => {
        const token = localStorage.getItem("token");
        if (token) {
            const tokenData = jwt(token);
            let userInfo = {};
            const scopes = tokenData && tokenData.scopes;
            userInfo.userName = tokenData.sub;
            userInfo.role = scopes && scopes.length > 0 ? scopes[0] : "ANONYMOUS";
            userInfo.scope = USER_ROLES[userInfo.role].scope;
            userInfo.userId = tokenData.userId;
            userInfo.providerId = tokenData.providerId;
            userInfo.customerId = tokenData.customerId;
            userInfo.subCustomerId = tokenData.subCustomerId;
            setUserInfo(userInfo);
        } else {
            setUserInfo(null);
        }
    }

    useEffect(() => {
        getUserInfo();
    }, [state])

    return (
        <AuthContext.Provider value={{ state, userInfo, setToAuthorized, setToNotAuthorized }}>
            {children}
        </AuthContext.Provider>
    );
};