import React, { useState, useEffect, memo } from "react";
import { CardContent, Box, CircularProgress, Typography } from '@mui/material';
import { CustomCard } from "../common/StyledComponents";
import { isDownlink, isUplink, STATISTIC_MIN_LIMIT, MAX_LOADED_PACKETS } from "../../constants/types.constant";
import { getMissedUplinks, getMissedDownlinks, getUsedDRMap, getFrequencies, getRegionByPacket, getAverageValue, removeUplinkDuplicates } from "../Worker/statistics";
import StatisticPanel from "../Statistics/StatisticPanel";

const Statistics = memo(({ ppp, lastJoin, isLimitExceeded }) => {

    const [packets, setPackets] = useState(null);
    const [lastJoinAccept, setLastJoinAccept] = useState(null);
    const [lastPacket, setLastPacket] = useState(null);
    const [uplinkList, setUplinkList] = useState([]);
    const [uniqueUplinkList, setUniqueUplinkList] = useState([]);
    const [uplinkFrequencies, setUplinkFrequencies] = useState([]);
    const [missedUplinks, setMissedUplinks] = useState({});
    const [downlinkList, setDownlinkList] = useState([]);
    const [downlinkFrequencies, setDownlinkFrequencies] = useState([]);
    const [missedDownlinks, setMissedDownlinks] = useState({});
    const [region, setRegion] = useState(null);
    const [usedDRMap, setUsedDRMap] = useState({});

    useEffect(() => {
        setPackets(ppp ? ppp : null);
        setLastJoinAccept(lastJoin);
        setLastPacket(lastJoin ? lastJoin : ppp?.[ppp.length - 1]);
        setRegion(null);
    }, [ppp])

    useEffect(() => {
        if (packets) {
            setUplinkList(packets.filter(isUplink));
            setDownlinkList(packets.filter(isDownlink));
            setRegion(getRegionByPacket(packets[0]));
            setMissedDownlinks(getMissedDownlinks(packets));
        }
    }, [packets])

    useEffect(() => {
        if (uplinkList) {
            setUsedDRMap(getUsedDRMap(region, uplinkList));
            setUplinkFrequencies(getFrequencies(uplinkList));
            setUniqueUplinkList(removeUplinkDuplicates(uplinkList));
        }
    }, [uplinkList])

    useEffect(() => {
        if (uniqueUplinkList) {
            setMissedUplinks(getMissedUplinks(uniqueUplinkList));
        }
    }, [uniqueUplinkList])

    useEffect(() => {
        if (downlinkList) {
            setDownlinkFrequencies(getFrequencies(downlinkList))
        }
    }, [downlinkList])

    const getAverageRSSI = (limit) => getAverageValue(limit ? uplinkList.slice(0, limit) : uplinkList, "rssi");
    const getAverageSNR = (limit) => getAverageValue(limit ? uplinkList.slice(0, limit) : uplinkList, "loRaSNR");

    return (
        <>
            {packets
                ? <Box>
                    <CustomCard sx={{ overflow: "auto" }}>
                        <CardContent sx={{ px: 4, py: 2 }}>
                            {packets.length > 0
                                ? <>
                                    {!lastJoinAccept &&
                                        <Box>
                                            {isLimitExceeded
                                                ? `The limit (${MAX_LOADED_PACKETS}) of loaded packets has been exceeded without finding the Join frame. Below are statistics for the last ${MAX_LOADED_PACKETS} packets.`
                                                : `Could not find any Join frame! Below is statistic for all packets.`
                                            }
                                        </Box>}
                                    <StatisticPanel
                                        time={new Date(lastPacket.ts).toLocaleString()}
                                        packetsCounter={{
                                            downlinks: downlinkList.length,
                                            uplinks: uplinkList.length,
                                            uniqueUplinks: uniqueUplinkList.length,
                                            total: packets.length,
                                            uplinkFrequencies: uplinkFrequencies.length,
                                            downlinkFrequencies: downlinkFrequencies.length
                                        }}
                                        averageData={{
                                            RSSI: getAverageRSSI(),
                                            limitRSSI: getAverageRSSI(STATISTIC_MIN_LIMIT),
                                            SNR: getAverageSNR(),
                                            limitSNR: getAverageSNR(STATISTIC_MIN_LIMIT)
                                        }}
                                        usedDRMap={usedDRMap}
                                        missedUplinks={missedUplinks}
                                        missedDownlinks={missedDownlinks}
                                        isJoin={lastJoinAccept ? true : false}
                                        region={region} />
                                </>
                                : <Box sx={{ px: 4, py: 2 }}>
                                    {!lastJoinAccept
                                        ? "There are no real-time packets to get statistics!"
                                        : "There are no packets after joining!"
                                    }
                                </Box>
                            }
                        </CardContent>
                    </CustomCard>
                </Box>
                : <Box className="d-flex-column-center" height="100%">
                    <CircularProgress color="primary" />
                    <Typography variant="title2" sx={{ mt: 2 }}>Trying to find last Join frame...</Typography>
                </Box>}
        </>)
});

export default Statistics;