import React, { memo } from "react";
import { CardContent, Tabs, Tab, CardMedia } from '@mui/material';
import { CustomCard } from "../common/StyledComponents"
import DownlinkGenerator from "../Input/DownlinkGenerator.jsx";


const ManualDownlink = memo(({ sensorEncoder = "", sensorModelName = "" }) => {
    return (
        <CustomCard >
            <CardMedia>
                <Tabs value="1">
                    <Tab label={"Generate"} value="1" />
                </Tabs>
            </CardMedia>
            <CardContent>
                <DownlinkGenerator sensorModelName={sensorModelName} sensorEncoder={sensorEncoder} />
            </CardContent>
        </CustomCard>
    );
})

export default ManualDownlink;