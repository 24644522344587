import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { CustomButton } from "../common/StyledComponents";

export default function NotFoundPage() {
    const navigate = useNavigate();

    const goHomeFunc = () => {
        navigate('/');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Typography variant="h1">
                404
            </Typography>
            <Typography variant="h6">
                The page you’re looking for doesn’t exist.
            </Typography>
            <CustomButton sx={{ marginTop: "30px" }} variant="contained" onClick={goHomeFunc}>Back Home</CustomButton>
        </Box>
    );
}