export const STATISTIC_MIN_LIMIT = 20;
export const MAX_LOADED_PACKETS = 3000;

export const MTYPES = {
    UNCONFIRMED_DATA_DOWN: "UNCONFIRMED_DATA_DOWN",
    UNCONFIRMED_DATA_UP: "UNCONFIRMED_DATA_UP",
    CONFIRMED_DATA_UP: "CONFIRMED_DATA_UP",
    CONFIRMED_DATA_DOWN: "CONFIRMED_DATA_DOWN",
    JOIN_ACCEPT: "JOIN_ACCEPT",
    JOIN_REQUEST: "JOIN_REQUEST",
}

export const MESSAGE_TYPES = {
    UPLINK: "Uplink",
    DOWNLINK: "Downlink",
    JOIN_ACCEPT: "Join Accept",
    JOIN_REQUEST: "Join Request",
}

export function isJoinAccept(stat) {
    return stat.messageType === MESSAGE_TYPES.JOIN_ACCEPT;
}

export function isJoinRequest(stat) {
    return stat.messageType === MESSAGE_TYPES.JOIN_REQUEST;
}

export function isJoin(stat) {
    return isJoinRequest(stat) || isJoinAccept(stat);
}

export function isDownlink(stat) {
    return stat.messageType === MESSAGE_TYPES.DOWNLINK || stat.messageType === MESSAGE_TYPES.JOIN_ACCEPT;
}

export function isUplink(stat) {
    return stat.messageType === MESSAGE_TYPES.UPLINK || stat.messageType === MESSAGE_TYPES.JOIN_REQUEST;
}

export const MAC_COMMANDS = {
    "02": {
        name: "LinkCheck",
        requestType: MESSAGE_TYPES.UPLINK,
        answerType: MESSAGE_TYPES.DOWNLINK
    },
    "03": {
        name: "LinkADR",
        requestType: MESSAGE_TYPES.DOWNLINK,
        answerType: MESSAGE_TYPES.UPLINK,
    },
    "04": {
        name: "DutyCycle",
        requestType: MESSAGE_TYPES.DOWNLINK,
        answerType: MESSAGE_TYPES.UPLINK
    },
    "05": {
        name: "RXParamSetup",
        requestType: MESSAGE_TYPES.DOWNLINK,
        answerType: MESSAGE_TYPES.UPLINK
    },
    "06": {
        name: "DevStatus",
        requestType: MESSAGE_TYPES.DOWNLINK,
        answerType: MESSAGE_TYPES.UPLINK
    },
    "07": {
        name: "NewChannel",
        requestType: MESSAGE_TYPES.DOWNLINK,
        answerType: MESSAGE_TYPES.UPLINK
    },
    "08": {
        name: "RXTimingSetup",
        requestType: MESSAGE_TYPES.DOWNLINK,
        answerType: MESSAGE_TYPES.UPLINK
    },
    "09": {
        name: "TXParamSetup",
        requestType: MESSAGE_TYPES.UPLINK,
        answerType: MESSAGE_TYPES.DOWNLINK
    },
    "0A": {
        name: "DlChannel",
        requestType: MESSAGE_TYPES.UPLINK,
        answerType: MESSAGE_TYPES.DOWNLINK
    },
    "0D": {
        name: "DeviceTime",
        requestType: MESSAGE_TYPES.UPLINK,
        answerType: MESSAGE_TYPES.DOWNLINK
    }
}