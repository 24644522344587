import React, { useState } from "react";
import StatisticCard from "../Statistics/StatisticCard";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { MAX_LOADED_PACKETS } from "../../constants/types.constant";
import { TableContainer, TableBody, TableHead, TableRow, TableCell, Button, Table } from '@mui/material';
import { Box, List, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { getMostUsedDR } from "../Worker/statistics";
import InfoListItem from "../Statistics/InfoListItem";

import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const StatisticPanel = ({ time, packetsCounter, averageData, usedDRMap, missedUplinks, missedDownlinks, isJoin, region }) => {

    const [popup, setPopup] = useState({ isOpen: false, type: null });
    const handleClose = () => setPopup({ isOpen: false, type: null });

    const options = {
        plugins: {
            legend: {
                display: false,
                onClick: null,
                position: "right",
                fullSize: true,
                maxHeight: "350px"
            },
            datalabels: {
                display: true,
                color: "green",
            }
        }
    }
    const getData = () => {
        let data = [packetsCounter.uplinks, packetsCounter.downlinks];
        return {
            labels: ['Uplinks', 'Downlinks'],
            datasets: [
                {
                    data: data,
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)'
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)'
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }

    const drawTable = (name, table) => {
        return (
            <div className="d-flex-column">
                <Typography variant="title3" sx={{ mb: 2 }}>{name}</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Data rate</TableCell>
                                <TableCell align="center">Number of used</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(table).map(function (k) {
                                return <TableRow key={k}>
                                    <TableCell align="center">{k}</TableCell>
                                    <TableCell align="center">{table[k]}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const showDialog = () => {
        switch (popup.type) {
            case "Downlinks":
                return <>
                    <DialogTitle>Missed downlink statistics</DialogTitle>
                    <DialogContent dividers sx={{ width: "500px" }}>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <InfoListItem
                                description="Downlink with MAC commands are answered in the next Uplink if they are successful"
                                value={`Missed Downlinks: ${missedDownlinks.missedMac} of ${missedDownlinks.allMac}`} />
                            <Divider variant="inset" component="li" />
                            <InfoListItem
                                description="Uplink with ADR ACK Req bit set, Downlink in response, next Uplink should have the ADR ACK Req bit cleared"
                                value={`Missed Downlinks: ${missedDownlinks.failedADRACK} of ${missedDownlinks.allADRACK}`} />
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </>
            case "DRUsage":
                return <>
                    <DialogTitle>Data rate usage</DialogTitle>
                    <DialogContent dividers sx={{ width: "600px" }}>
                        <Box className="d-flex" sx={{ justifyContent: "space-around" }}>
                            {drawTable("Since Join", usedDRMap.sinceJoin)}
                            {drawTable("The most recent 20 ULs", usedDRMap.limitPacket)}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </>
        }
    }

    return (
        <>
            <Box sx={{ px: 4, py: 2 }}>
                <Box className="d-flex-center" sx={{ justifyContent: "flex-end", mb: 2 }}>
                    <Box>Last {isJoin ? "Join" : ""} frame: {time} </Box>
                    <AccessTimeIcon sx={{ ml: 1 }} color="primary" />
                </Box>
                <>
                    <Box className="d-flex-center" sx={{ mb: 4, maxHeight: "150px" }}>
                        <Doughnut data={getData()} options={options} />
                        <Box sx={{ pl: 4 }}>
                            <Box sx={{ pb: 1 }}>Total amount of packets: {packetsCounter.total} </Box>
                            <Box className="d-flex-center">
                                <Box>
                                    <Box>Number of UL: {packetsCounter.uplinks}</Box>
                                    <Box>Number of unique UL: {packetsCounter.uniqueUplinks}</Box>
                                    <Box>Number of DL: {packetsCounter.downlinks}</Box>
                                </Box>
                                <Box sx={{ pl: 4 }} >
                                    <Box>Number of UL frequencies used: {packetsCounter.uplinkFrequencies}</Box>
                                    <Box>Number of DL frequencies used: {packetsCounter.downlinkFrequencies}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ maxWidth: "1500px", margin: "0 auto" }}>
                        <Box className="d-flex-center" gap="20px 10px" sx={{ mt: 4, mb: 4, flexWrap: "wrap", justifyContent: "space-around" }}>
                            <StatisticCard title="Average RSSI (dBm)" valueSinceJoin={averageData.RSSI} valueRecentLimit={averageData.limitRSSI} isJoin={isJoin} />
                            <StatisticCard title="Average SNR (dB)" valueSinceJoin={averageData.SNR} valueRecentLimit={averageData.limitSNR} isJoin={isJoin} />
                            <StatisticCard title="Most used DR" valueSinceJoin={region && getMostUsedDR(usedDRMap.sinceJoin)}
                                valueRecentLimit={region && getMostUsedDR(usedDRMap.limitPacket)}
                                seeDetails={() => setPopup({ isOpen: true, type: "DRUsage" })}
                                error={!region && "Could not determine the region"} isJoin={isJoin} />
                        </Box>
                        <Box className="d-flex-center" gap="30px" sx={{ mt: 4, mb: 4, flexWrap: "wrap" }}>
                            <StatisticCard title="MISSED UPLINK" valueSinceJoin={missedUplinks.sinceJoin} valueRecentLimit={missedUplinks.limitPacket} isJoin={isJoin} />
                            <StatisticCard title="MISSED DOWNLINK" valueSinceJoin={`${missedDownlinks.allMissedDownkinks} of ${missedDownlinks.allCheckedDownlinks}`}
                                info="Estimate the DL packet success rate by observing device responses"
                                seeDetails={() => setPopup({ isOpen: true, type: "Downlinks" })}
                                isJoin={isJoin} />
                        </Box>
                    </Box>
                </>
            </Box>
            <Dialog onClose={handleClose} open={popup.isOpen}>
                {showDialog()}
            </Dialog>
        </>
    )
}

export default StatisticPanel;