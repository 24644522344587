import React, { useState, useEffect } from "react";
import { CardContent, Tabs, Tab, CardMedia } from '@mui/material';
import { CustomCard } from "../common/StyledComponents"
import DownlinkGenerator from "../Input/DownlinkGenerator.jsx";
import DownlinkQueue from "./DownlinkQueue"


export default function Downlink({ sensorEncoder, sensorModelName, deviceId }) {

    const downlinks = [
        { id: 1, text: "Generate" },
        { id: 2, text: "Downlink Queue" }
    ];

    const [activeDownId, setActiveDownId] = useState(sensorEncoder ? 1 : 2);

    useEffect(() => {
        if (!sensorEncoder) {
            setActiveDownId(2);
        }
    }, [sensorEncoder])

    const handleTabChange = (event, newValue) => setActiveDownId(newValue);

    return (
        <CustomCard>
            <CardMedia>
                <Tabs value={activeDownId} onChange={handleTabChange}>
                    {downlinks.map(el => <Tab disabled={!sensorEncoder && el.id === 1} label={el.text} key={el.id} value={el.id} />)}
                </Tabs>
            </CardMedia>
            <CardContent>
                {activeDownId === 1
                    ? sensorEncoder && <DownlinkGenerator
                        sensorModelName={sensorModelName}
                        sensorEncoder={sensorEncoder}
                        deviceId={deviceId} />
                    : <DownlinkQueue deviceId={deviceId} />
                }
            </CardContent>
        </CustomCard>
    );
}
