module.exports = {encodeDownlink : encodeDownlink}

function encodeDownlink(input) {
    if ("demo_menu" in input){
        if ("custom_screen" in input.demo_menu){
            return {
                "110" : [80]
            }
        } else if ("text_group" in input.demo_menu){
            if ("write" in input.demo_menu.text_group){
                var type = input.demo_menu.text_group.write.value_type
                if (type === ""){
                    type = 0
                }
                var text = input.demo_menu.text_group.write.string_text
                var text_length = text.length
                var temp = new Array(text_length)
                for (var i = 0; i < text_length; i++){
                    temp[i] = text.charCodeAt(i)
                }
                if (text_length > 255){
                    text_length = 255
                }
                return {
                    "102": [0xC5, 0x01, type, text_length].concat(temp)
                }
            }

        } else if ("icon_group" in input.demo_menu){
            if ("write" in input.demo_menu.icon_group){
                var icontype = input.demo_menu.icon_group.write.icon_type
                if (icontype === ""){
                    icontype = 0
                }
                var visibility = input.demo_menu.icon_group.write.icon_visibility
                if (visibility === ""){
                    visibility = 0
                }
                return {
                    "102": [0xC5, 0x02, icontype, visibility]
                }
            }

        }

    }
 
}