import React from "react";
import { ListItemText, ListItem, ListItemIcon, Typography, } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const InfoListItem = ({ description, value }) => {
    return (
        <>
            <ListItem>
                <ListItemIcon>
                    <ArrowForwardIosIcon color="primary" />
                </ListItemIcon>
                <ListItemText >
                    <Typography>{description}</Typography>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText inset>
                    <Typography>{value}</Typography>
                </ListItemText>
            </ListItem>
        </>
    )
}

export default InfoListItem;