import React, { useState, useContext, useEffect } from "react";
import NotificationContext from "../../context/NotificationContext"
import AuthContext from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import { Box, Typography, FormControl, MenuItem } from '@mui/material';
import { CustomSelect, CustomField, CustomButton } from "../common/StyledComponents"

import { getTokens } from "../Worker/ns";
const NSOptions = ["https://lorawan-ns-na.tektelic.com", "https://lorawan-ns-eu.tektelic.com", "https://lorawan-ns-dev.tektelic.com", "Other"];

export default function LoginPage() {
    const notificationCtx = useContext(NotificationContext);
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [hostName, setHostName] = useState(NSOptions[0]);
    const [adress, setAdress] = useState("");

    useEffect(() => {
        authCtx.setToNotAuthorized();
        localStorage.clear();
    }, [])

    async function tryConnect() {
        let host = hostName !== "Other" ? hostName : adress;
        if (await getTokens(host, username, password)) {
            authCtx.setToAuthorized();
            localStorage.setItem("host", host);
            navigate("/ns");
        } else {
            notificationCtx.error('Invalid Credentials!');
        }
    }

    return (
        <Box className="d-flex-center" height="95vh" marginTop="5vh">
            <Box className="login d-flex-center">
                <form>
                    <FormControl fullWidth>
                        <Typography variant="title3">Network Server</Typography>
                        <CustomSelect
                            className="ns-select"
                            value={hostName}
                            displayEmpty
                            onChange={e => setHostName(e.target.value)}
                            required
                        >
                            {NSOptions.map((option, index) => <MenuItem value={option} key={index}>{option}</MenuItem>)}
                        </CustomSelect>
                    </FormControl>
                    {hostName === "Other" && (
                        <FormControl fullWidth>
                            <Typography variant="title3">Address</Typography>
                            <CustomField
                                placeholder="http(s)://YOUR_URL.COM (no backslash)"
                                type="text"
                                required
                                value={adress}
                                onChange={e => setAdress(e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    )}
                    <FormControl fullWidth>
                        <Typography variant="title3">Username</Typography>
                        <CustomField
                            placeholder="Username"
                            type="text"
                            required
                            value={username}
                            onChange={e => setUserName(e.target.value)}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Typography variant="title3">Password</Typography>
                        <CustomField
                            placeholder="Password"
                            type="password"
                            required
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            fullWidth
                        />
                    </FormControl>
                    <Box className="d-flex" sx={{ alignItems: "flex-end", pt: 3 }}>
                        <Link to="/manual" className="offline">Use offline</Link>
                        <CustomButton onClick={tryConnect} variant="contained" disabled={!username || !password}>Connect</CustomButton>
                    </Box>
                </form>
            </Box>
        </Box>
    );
};
