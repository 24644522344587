import React, { useState } from "react";
import { MenuItem } from '@mui/material';
import { CustomSelect, CustomField } from "../common/StyledComponents";
import { useEffect } from "react";

const ParameterInput = ({ element, onChange, isDisabled, oldValue = "" }) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(oldValue)
    }, [oldValue])

    const options = Object.keys(element).filter((el) => {
        return ((/^(opt)[0-9]{1,2}$/.test(el)) && element[el] !== '')
    }).map((el) => {
        return {
            label: element[el],
            value: element[`val` + el.substr(3)]
        }
    });

    const handleChange = e => {
        setValue(e.target.value);
        onChange(element, e.target.value)
    };

    if (options.length) {
        return <CustomSelect
            fullWidth
            value={value}
            size="small"
            disabled={isDisabled}
            onChange={handleChange}
            displayEmpty
            required
        >
            <MenuItem value="" style={{ display: "none" }}>{"Select value"}</MenuItem>
            {options.map((option, index) => <MenuItem value={option.value} key={index}>{option.label}</MenuItem>)}
        </CustomSelect>
    }
    else {
        return <CustomField fullWidth disabled={isDisabled} size="small" placeholder={"Type value"} value={value} onChange={handleChange} />
    }
}

export default ParameterInput;