import { createTheme } from '@mui/material/styles';
// import colors from './colors.module.scss';

export const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6FA",
      secondary: "#EDEDF5"
    },
    text: {
      primary: "#42454E",
      secondary: "#FFFFF"
    },
    primary: {
      main: "#59CAFA",
      contrastText: "#ffffff",
      white: "#ffffff"
      // light: colors.black,
      // contrastText: colors.white
    },
    redd: {
      main: "#000",
    }
    // secondary: {
    //   main: colors.black,
    //   contrastText: colors.black
    // },
    // error: {
    //   main: colors.red,
    //   light: colors.lightRed
    // }
  },
  typography: {
    fontFamily: 'Raleway, Arial',
    fontSize: 14,

    title3: {
      fontWeight: "bold",
      fontSize: 16
    },
    logo: {
      fontWeight: "bold",
      fontSize: "1.4rem"
    }
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 500,
    },
  },
});