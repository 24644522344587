import React, { useState, useContext } from "react";
import { Box, FormControl, Grid, Switch, Typography, TableContainer, TableRow, Checkbox, FormControlLabel } from '@mui/material';
import NotificationContext from "../../context/NotificationContext";
import { CustomButton, CustomField, CustomTable } from "../common/StyledComponents";
import { TableCell, TableHead, TableBody } from '@mui/material';
import { useEffect } from "react";
import { getDownlinkQueue, sendDownlink, deleteDownlinkQueue } from "../Worker/ns.js";
import { checkvalidHexOrBase64 } from "../../utils";

export default function DownlinkQueue({ deviceId }) {
    const notificationCtx = useContext(NotificationContext);

    const [downlinkQueue, setDownlinkQueue] = useState(null);
    const [isHex, setisHex] = useState(true);
    const [payload, setPayload] = useState("");
    const [port, setPort] = useState("");
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        setDownlinkQueue(null);
    }, [deviceId])

    async function getDownlinkQueueNS() {
        var resp = await getDownlinkQueue(deviceId);
        setDownlinkQueue(resp);
    }

    async function sendDownlinkNS() {
        let downlink = checkvalidHexOrBase64(payload, isHex)
        if (downlink !== "" && downlink !== undefined) {
            console.log("Sending downlink: " + downlink + " to port: " + port)
            let res = await sendDownlink(downlink, port, deviceId, confirmed)
            if (await res.status === 200) {
                notificationCtx.success("Send successful!")
            } else {
                notificationCtx.error("Send failed!")
            }
        } else {
            notificationCtx.error("Invalid payload!")
        }
    }

    async function clearDownlinkQueue() {
        var resp = await deleteDownlinkQueue(deviceId)
        if (resp.status === 200) {
            getDownlinkQueue()
            notificationCtx.success("Successfully cleared!")
        } else {
            notificationCtx.error("Failed to clear!")
        }
    }

    const handleType = event => setisHex(!event.target.checked);

    const handleConfirmed = (event) => setConfirmed(event.target.checked);

    return (
        <Box className="d-flex-column" height="100%">
            <Box className="box">
                <Typography variant="title3">Add downlink to queue</Typography>
                <Box className="d-flex" alignItems="start" marginTop="15px">
                    <FormControl sx={{ width: "50%" }}>
                        <CustomField
                            label="Payload"
                            type="text"
                            fullWidth
                            value={payload}
                            onChange={e => setPayload(e.target.value)}
                        />
                    </FormControl>
                    <FormControl sx={{ width: "25%" }} >
                        <CustomField
                            label="Port"
                            type="text"
                            fullWidth
                            value={port}
                            onChange={e => setPort(e.target.value)}
                        />
                    </FormControl>
                    <CustomButton className="send-btn" disabled={!deviceId || !payload || !port} variant="contained" onClick={sendDownlinkNS}>Send</CustomButton>
                </Box>
                <Box className="d-flex-center" sx={{justifyContent: "flex-start", gap: "100px" }}>
                    <FormControl>
                        <Grid component="label" container alignItems="center" spacing={0}>
                            <Grid item>Hex</Grid>
                            <Grid item>
                                <Switch checked={!isHex} onChange={handleType} value="checked" />
                            </Grid>
                            <Grid item>Base64</Grid>
                        </Grid>
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox checked={confirmed} onChange={handleConfirmed} />}
                            label="Confirmed" />
                    </FormControl>
                </Box>
            </Box>
            <Box className="d-flex" alignItems="center" margin={2}>
                <CustomButton variant="contained" disabled={!deviceId} onClick={getDownlinkQueueNS}>Get queue</CustomButton>
                <CustomButton variant="contained" disabled={!deviceId} onClick={clearDownlinkQueue}>Clear queue</CustomButton>
            </Box>
            <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
                {downlinkQueue && <TableContainer>
                    <CustomTable stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={"50%"}>{"Message ID"}</TableCell>
                                <TableCell width={"15%"}>{"Port"}</TableCell>
                                <TableCell width={"15%"}>{"Confirmed"}</TableCell>
                                <TableCell width={"30%"}>{"Data"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {downlinkQueue.length > 0
                                ? <>
                                    {downlinkQueue.map((downlink, index) =>
                                        <TableRow key={index}>
                                            <TableCell>{downlink.msgId}</TableCell>
                                            <TableCell>{downlink.port}</TableCell>
                                            <TableCell>{downlink.confirmed ? "True" : "False"}</TableCell>
                                            <TableCell>{downlink.data}</TableCell>
                                        </TableRow>)}
                                </>
                                : <TableRow >
                                    <TableCell colSpan={4}>No messages to show</TableCell>
                                </TableRow>}
                        </TableBody>
                    </CustomTable>
                </TableContainer>}
            </Box>
        </Box>
    );
}
