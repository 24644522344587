import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { getSensorModels } from "../Worker/filemanager.js";
import { Divider, Typography, FormControl, MenuItem, Box, List, InputAdornment, ListItem, ListItemButton, ListItemText, Chip, InputLabel, createFilterOptions } from '@mui/material';
import { CustomSelect, Main, CustomField, CustomAutocomplete } from "../common/StyledComponents"
import CustomDrawer from "../common/CustomDrawer/index.jsx";
import { sortArrayOfObjects } from "../../utils.js"
import Uplink from "./Uplink.jsx";
import Downlink from "./Downlink.jsx";
import debounce from 'lodash.debounce';
import { isJoin, isJoinAccept, isJoinRequest, MAX_LOADED_PACKETS } from "../../constants/types.constant.js";
import AuthContext, { USER_SCOPES } from "../../context/AuthContext";
import { getCustomerApplications, getSubCustomerApplications, getApplicationDevices, getDeviceById, getDevicePackets } from "../Worker/ns.js";
import { sortPackets } from "../Worker/statistics.js";
import Statistics from "./Statistics.jsx";
import lora from "lora-packet";

const ITEMS_PER_PAGE = 100;
const MAX_DEVICES = 500;
const MAX_PACKETS = 1000;
const PACKETS_PER_PAGE = 300;

const filter = createFilterOptions();

const getSensors = () => {
    let sensors = getSensorModels() || [];
    sensors = sortArrayOfObjects(sensors, "sensor");
    sensors = [{ sensor: "Custom sensor" }, ...sensors]
    return sensors;
}

export default function NSDecoder() {
    const { userInfo } = useContext(AuthContext);
    const sensors = getSensors();
    const [selectedSensor, setSelectedSensor] = useState(sensors[0]);
    const [applications, setApplication] = useState([]);
    const [selectedApplication, setSelectedApplication] = useState("");
    const [devices, setDevices] = useState(null);
    const [isLargeNumbers, setIsLargeNumbers] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState({
        id: "",
    });
    const [allPackets, setAllPackets] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(null);
    const [linkType, setLinkType] = useState("Uplink");
    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const [lastJoinPackets, setLastJoinPackets] = useState(null);
    const [lastJoinAccept, setLastJoinAccept] = useState(null);
    const [uplinkPackets, setUplinkPackets] = useState(null);
    const [uplinkPage, setUplinkPage] = useState(1);
    const [isLimitExceeded, setIsLimitExceeded] = useState(false);

    const openDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);

    var token = localStorage.getItem("token")
    var nsurl = localStorage.getItem("host")
    var socket = useMemo(() => {
        return new WebSocket(`${nsurl.replace("http", "ws")}/api/ws?token=${token}`);
    }, [token, nsurl])

    function getCmid() {
        let id = localStorage.getItem("id")
        id = parseInt(id) + 1
        localStorage.setItem("id", id)
        return id
    }

    function waitForSocket(socket, callback) {
        setTimeout(() => {
            if (socket.readyState === 1) {
                console.log("Socket connected")
                callback()
            } else {
                waitForSocket(socket, callback)
            }
        }, 100)
    }

    const handleSensorChange = (sensorName) => {
        let sensor = sensors.find(sensor => sensor.sensor === sensorName);
        setSelectedSensor(sensor);
    }

    const subscribeForDeviceStatsData = (deviceId) => {
        try {
            let id = getCmid()
            waitForSocket(socket, function () {
                socket.send(JSON.stringify({
                    "subCmds": [
                        {
                            "entityType": "DEVICE",
                            "entityId": deviceId,
                            "type": "STATS",
                            "cmdId": id
                        }
                    ]
                }))
            })
            console.log('ID: ' + id)
            console.log("Subcribed to device : " + deviceId)
        } catch (error) {
            console.log(error)
        }
    }

    const unsubscribeFromDeviceStatsData = () => {
        try {
            waitForSocket(socket, function () {
                socket.send(JSON.stringify({
                    "subCmds": [
                        {
                            "entityType": "DEVICE",
                            "type": "STATS",
                            "cmdId": localStorage.getItem("id"),
                            "unsubscribe": true
                        }
                    ]
                }))
                console.log("Unsubcribed from device: " + selectedDevice.id)
                //    socket.close()
                //    socket = new WebSocket(`${nsurl.replace("http", "ws")}/api/ws?token=${token}`);
                //    console.log("Opening new socket")
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (selectedDevice.id !== "") {
            subscribeForDeviceStatsData(selectedDevice.id);
            setUplinkPage(1);
            updatePackets(selectedDevice.id);
        }
    }, [selectedDevice.id])

    useEffect(() => {
        if (allPackets) {
            let index = allPackets.findIndex(isJoinAccept);
            if (index === -1) {
                console.log("Not FOUND. LOAd MORE")
                if (!isAllLoaded && allPackets.length < MAX_LOADED_PACKETS) {
                    updatePackets(selectedDevice.id)
                } else {
                    if (allPackets.length >= MAX_LOADED_PACKETS) {
                        console.log("LIMIT!!!!", MAX_LOADED_PACKETS);
                        setIsLimitExceeded(true);
                        setLastJoinPackets(allPackets.slice(0, MAX_LOADED_PACKETS));
                    } else {
                        setLastJoinPackets(allPackets);
                    }
                    setLastJoinAccept(null);
                }
            } else {
                setLastJoinAccept(allPackets[index]);
                setLastJoinPackets(allPackets.slice(0, index));
            }
        } else {
            setLastJoinAccept(null);
        }
        setUplinkPackets(getPacketsWithInfo());
    }, [allPackets])

    const handleDeviceChange = (device) => {
        setAllPackets(null);
        setIsAllLoaded(false);
        setLastJoinPackets(null);
        setLastJoinAccept(null);
        setIsLimitExceeded(false);
        unsubscribeFromDeviceStatsData();
        setSelectedDevice(device);
    }

    socket.onmessage = function (event) {
        try {
            let Data = JSON.parse(event.data)
            let id = parseInt(localStorage.getItem("id"))
            if (Data.subscriptionId === id) {
                let newData = sortPackets(Data.data);
                if (newData.length > 1) {
                    setAllPackets(packets => [...newData, ...packets || []])
                } else if (newData.length === 1) {
                    setAllPackets(packets => [newData[0], ...packets || []])
                    if (isJoin(newData[0])) {
                        updateKeys()
                    }
                }
            } else {
                socket.send(JSON.stringify({
                    "subCmds": [
                        {
                            "entityType": "DEVICE",
                            "type": "STATS",
                            "cmdId": Data.subscriptionId,
                            "unsubscribe": true
                        }
                    ]
                }))
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function updateApplications() {
        let resp;
        if (userInfo.scope == USER_SCOPES.CUSTOMER) {
            resp = await getCustomerApplications()
        } else if (userInfo.scope == USER_SCOPES.SUBCUSTOMER) {
            resp = await getSubCustomerApplications(userInfo.subCustomerId)
        }
        if (resp !== null) {
            let apps = [];
            for (var i = 0; i < resp.length; i++) {
                let item = {}
                item.name = resp[i].name
                item.id = resp[i].id.id
                apps.push(item)
            }
            setApplication(apps)
        }
    }

    async function updatePackets(deviceId, onComplete = () => { }) {
        let lastElement = allPackets?.slice(-1).pop();
        const resp = await getDevicePackets(deviceId, MAX_PACKETS, lastElement?.ts, lastElement?.index);
        if (resp !== null) {
            if (resp.length < MAX_PACKETS) { setIsAllLoaded(true) }
            let newPackets = sortPackets(resp);
            setAllPackets(packets => [...packets || [], ...newPackets]);
        }
        onComplete();
    }

    async function updateKeys() {
        console.log("Updating keys")
        if (selectedDevice.id !== "") {
            const resp = await getDeviceById(selectedDevice.id);
            if (resp !== null) {
                let temp = selectedDevice;
                temp.nwkSKey = resp.nwkSKey
                temp.appSKey = resp.appSKey
                setSelectedDevice(temp)
            }
        }
    }

    function requestsArray(id, count) {
        let array = [];
        for (let i = 1; i < count; i++) {
            let searhParam = { page: i }
            let request = getApplicationDevices(id, searhParam)
            array.push(request);
        }
        return array;
    }

    const converToDevices = (arr) => {
        let newDevices = [];
        for (var i = 0; i < arr.length; i++) {
            let item = {}
            item.name = arr[i].name
            item.eui = arr[i].deviceEUI
            item.id = arr[i].id.id
            item.appKey = arr[i].appKey;
            item.nwkSKey = arr[i].nwkSKey
            item.appSKey = arr[i].appSKey
            item.modelName = arr[i].deviceModelName;
            newDevices.push(item);
        }
        return newDevices;
    }

    async function updateDevices(id) {
        setDevices(null);
        setSelectedDevice({ id: "" });
        setIsLargeNumbers(false);
        const resp = await getApplicationDevices(id)
        if (resp !== null) {
            let devices = converToDevices(resp.data);
            setDevices(devices)
            let totalDevices = resp.totalElements;
            if (totalDevices > ITEMS_PER_PAGE) {
                let pages = totalDevices < MAX_DEVICES ? Math.ceil(totalDevices / ITEMS_PER_PAGE) : MAX_DEVICES / ITEMS_PER_PAGE;
                Promise.all(requestsArray(id, pages)).then(allResults => {
                    const total = allResults.reduce((arr, row) => {
                        return arr.concat(row.data);
                    }, []);
                    let newDevices = converToDevices(total);
                    if (totalDevices > MAX_DEVICES) {
                        setIsLargeNumbers(true);
                        newDevices.push({ id: "tooManyDevs", name: "Too many devices. Please use search" })
                    }
                    setDevices(oldDevices => [...oldDevices, ...newDevices]);
                })
            }
        }
    }

    useEffect(() => {
        if (userInfo) {
            updateApplications();
            localStorage.setItem("id", 0)
        }
    }, [userInfo])

    useEffect(() => {
        if (selectedApplication !== "") {
            updateDevices(selectedApplication);
        }
    }, [selectedApplication])

    const forwardTraverse = (packets) => {
        let packetsWithSessionKeys = packets;
        if (packets) {
            let currentSessionKeys = selectedDevice.nwkSKey && selectedDevice.appSKey ? {
                NwkSKey: Buffer.from(selectedDevice.nwkSKey, "hex"),
                AppSKey: Buffer.from(selectedDevice.appSKey, "hex")
            } : null;
            for (let i = 0; i < packetsWithSessionKeys.length; i++) {
                if (isJoinAccept(packetsWithSessionKeys[i])) break;
                if (!packetsWithSessionKeys[i].sessionKeys) {
                    packetsWithSessionKeys[i].sessionKeys = currentSessionKeys;
                }
            }
        }
        return packetsWithSessionKeys;
    }

    const backwardTraverse = (packets) => {
        let packetsWithSessionKeys = packets;
        let currentNetId, currentAppNonce, currentDevNonce;
        let currentSessionKeys = null;
        let appKey = Buffer.from(selectedDevice.appKey, "hex");
        if (packetsWithSessionKeys) {
            for (let i = packetsWithSessionKeys.length - 1; i >= 0; i--) {
                if (!packetsWithSessionKeys[i].sessionKeys) {
                    if (isJoinAccept(packetsWithSessionKeys[i])) {
                        let payloadData = lora.fromWire(Buffer.from(packetsWithSessionKeys[i].rawPayload, 'base64'))
                        payloadData = lora.fromWire(lora.decryptJoinAccept(payloadData, appKey));
                        currentAppNonce = payloadData.AppNonce;
                        currentNetId = payloadData.NetID;
                        if (currentNetId && currentAppNonce && currentDevNonce) {
                            currentSessionKeys = lora.generateSessionKeys(appKey, currentNetId, currentAppNonce, currentDevNonce);
                        }
                    }
                    else if (isJoinRequest(packetsWithSessionKeys[i])) {
                        let payloadData = lora.fromWire(Buffer.from(packetsWithSessionKeys[i].rawPayload, 'base64'))
                        currentDevNonce = payloadData.DevNonce;
                    }
                    packetsWithSessionKeys[i].sessionKeys = currentSessionKeys;
                } else break;
            }
        }
        return packetsWithSessionKeys;
    }

    const addSessionKeysInformation = (packets) => {
        let packetsWithSessionKeys;
        if (packets) {
            packetsWithSessionKeys = forwardTraverse(packets);
            packetsWithSessionKeys = backwardTraverse(packetsWithSessionKeys);
        }
        return packetsWithSessionKeys;
    }

    function getPacketsWithInfo() {
        if (allPackets) {
            let n = uplinkPage * PACKETS_PER_PAGE;
            let packets = allPackets.slice(0, n);
            let newPackets = addSessionKeysInformation(packets);
            return newPackets;
        } else return null;
    }

    async function loadMorePackets(onComplete = () => { }) {
        if (allPackets) {
            let newPage = uplinkPage + 1;
            if (allPackets.length < newPage * MAX_PACKETS) {
                await updatePackets(selectedDevice.id);
            }
            setUplinkPage(newPage);
        }
        onComplete();
    }

    useEffect(() => {
        setUplinkPackets(getPacketsWithInfo());
    }, [uplinkPage])

    const showContent = () => {
        switch (linkType) {
            case "Uplink":
                return <Uplink packets={uplinkPackets}
                    sensorDecoder={selectedSensor?.decoder}
                    device={selectedDevice}
                    updateKeys={updateKeys}
                    isAllLoaded={isAllLoaded}
                    updatePackets={loadMorePackets} />
            case "Downlink":
                return <Downlink sensorEncoder={selectedSensor?.encoder}
                    sensorModelName={selectedSensor?.model}
                    deviceId={selectedDevice.id}
                />
            case "Statistics":
                return <Statistics ppp={lastJoinPackets} lastJoin={lastJoinAccept} isLimitExceeded={isLimitExceeded} />
        }
    }

    async function getFilteredDevices(applicationId, filterStr) {
        let newOptions = [];
        let searchParams = { filter: filterStr }
        const resp = await getApplicationDevices(applicationId, searchParams);
        if (resp !== null) {
            let data = resp.data;
            newOptions = converToDevices(data);
        }
        setFilteredOptions(newOptions);
    }

    useEffect(() => {
        if (isLargeNumbers && inputValue !== "") {
            debouncedFilter(selectedApplication, inputValue);
        }
    }, [inputValue])

    const debouncedFilter = useCallback(debounce((app, str) => { getFilteredDevices(app, str) }, 200), []);

    const filterOptions = (options, state) => {
        if (state.inputValue !== "") {
            if (isLargeNumbers) {
                const filtered = filteredOptions ? filter(filteredOptions, state) : filter([], state);
                return filtered;
            }
            else return filter(options, state);
        }
        else return options;
    }

    const MenuProps = { PaperProps: { style: { maxHeight: 375 } } };

    return (
        <>
            <CustomDrawer isOpen={isOpen} closeFunc={closeDrawer}>
                <h3>Device Settings</h3>
                <Box className="drawer-container">
                    <FormControl fullWidth>
                        <InputLabel>Select sensor</InputLabel>
                        <CustomSelect
                            label="Select sensor"
                            value={selectedSensor.sensor}
                            displayEmpty
                            onChange={e => handleSensorChange(e.target.value)}
                            required
                            MenuProps={MenuProps}
                        >
                            {sensors.map((option, index) => <MenuItem value={option.sensor} key={index}>{option.sensor}</MenuItem>)}
                        </CustomSelect>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Select application</InputLabel>
                        <CustomSelect
                            label="Select application"
                            value={selectedApplication}
                            displayEmpty
                            onChange={e => setSelectedApplication(e.target.value)}
                            required
                            MenuProps={MenuProps}
                        >
                            {sortArrayOfObjects(applications, "name").map((option, index) => <MenuItem value={option.id} key={index}>{option.name}</MenuItem>)}
                        </CustomSelect>
                    </FormControl>
                    <FormControl fullWidth>
                        {devices ?
                            <>
                                {(devices.length > 0)
                                    ? <CustomAutocomplete
                                        inputValue={inputValue}
                                        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                                        value={selectedDevice.id !== "" ? selectedDevice : null}
                                        onChange={(_, device) => handleDeviceChange(device)}
                                        filterOptions={filterOptions}
                                        getOptionDisabled={(option) => option.id === "tooManyDevs"}
                                        disabled={!selectedApplication}
                                        options={devices}
                                        getOptionLabel={(device) => device.name ? device.name : ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        disableClearable
                                        renderInput={(params) => (
                                            (selectedDevice && selectedDevice.id !== "")
                                                ? <CustomField
                                                    {...params}
                                                    label="Select device"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                <InputAdornment position="end">
                                                                    {selectedDevice.modelName && <Chip className="device-model" size="small" label={selectedDevice.modelName} />}
                                                                </InputAdornment>
                                                                {params.InputProps?.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                                : <CustomField {...params} label="Select device" />
                                        )}
                                    />
                                    : <CustomField placeholder="There is no available devices" disabled fullWidth />}
                            </>
                            : <CustomField placeholder="Select device" disabled fullWidth />
                        }
                    </FormControl>
                </Box>
                <Divider />
                <h3>Application</h3>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton selected={linkType === "Uplink"} onClick={() => setLinkType("Uplink")}>
                            <ListItemText primary="Packet Decoder" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton selected={linkType === "Downlink"} onClick={() => setLinkType("Downlink")}>
                            <ListItemText primary="Packet Encoder" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton selected={linkType === "Statistics"} onClick={() => setLinkType("Statistics")}>
                            <ListItemText primary="Statistics" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </CustomDrawer>
            <Box className="settings-btn d-flex-center" onClick={openDrawer}>
                <Typography variant="title3">{selectedSensor.sensor}</Typography>
            </Box>
            <Main open={isOpen}>
                {selectedDevice.id && showContent()}
            </Main>
        </>
    );
}
