import React, { useState } from 'react';
import NotificationContext, { STATES } from "./NotificationContext"

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);
    const [notificationText, setNotificationText] = useState(null);
    const success = (text) => {
        window.scroll(0, 0);
        setNotificationText(text);
        setNotification(STATES.SUCCESS);
    };
    const error = (text) => {
        window.scroll(0, 0);
        setNotificationText(text);
        setNotification(STATES.ERROR);
    };
    const clear = () => {
        setNotificationText(null);
        setNotification(null);
    };
    return (
        <NotificationContext.Provider
            value={{ success, error, clear, notification, notificationText }}
        >
            {children}
        </NotificationContext.Provider>
    );
};