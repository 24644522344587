import React, { memo } from 'react'
import DownlinkGeneratorSettings from "./DownlinkGeneratorSettings"

const getCategories = (data) => {
    let categories = [];
    for (let item in data) {
        let obj = {
            name: data[item]["category_name"],
            description: data[item]["category_description"]
        }
        if (categories.some(cat => cat.name === obj.name)) {
            continue
        } else {
            if (obj.name !== "") {
                categories.push(obj)
            }
        }
    }
    return categories;
}

const groupData = (rawData) => {
    var unique = []
    for (let item in rawData) {
        let parameter = rawData[item];
        parameter["parameter_name"] = item;

        let categoryIndex = unique.findIndex(obj => obj.category === parameter["category_name"]);
        if (categoryIndex > -1) {
            let groupIndex = unique[categoryIndex].groups.findIndex(obj => obj.group === parameter["group_name"]);
            if (groupIndex > -1) {
                unique[categoryIndex].groups[groupIndex].parameters.push(parameter);
            }
            else {
                unique[categoryIndex].groups.push({
                    group: parameter["group_name"],
                    parameters: [parameter]
                })
            }
        }
        else {
            unique.push({
                category: parameter["category_name"],
                groups: [{
                    group: parameter["group_name"],
                    parameters: [parameter]
                }]
            })
        }
    }
    return unique;
}


const DownlinkGenerator = memo(({
    sensorModelName,
    sensorEncoder,
    deviceId
}) => {

    const sensorModel = require("../../sensormodels" + sensorModelName);
    const categories = getCategories(sensorModel);
    const data = groupData(sensorModel);

    return (
        <DownlinkGeneratorSettings
            sensorEncoder={sensorEncoder}
            deviceId={deviceId}
            categories={categories}
            data={data} />
    )
})

export default DownlinkGenerator;
