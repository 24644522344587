import React, { useState, useContext, useEffect, memo } from "react";
import lora from "lora-packet";
import { CardHeader, CardContent, CardMedia, Box, Tabs, Tab, Switch, Checkbox, Grid, FormControl, FormControlLabel, Tooltip } from '@mui/material';
import { CustomField, CustomButton, CustomCard } from "../common/StyledComponents";
import NotificationContext from "../../context/NotificationContext.jsx";
import CodeBox from "../common/CodeBox/index.jsx";
import { checkvalidHex, checkvalidBase64, convertToUint8Array, hexStringToBytes } from "../../utils";
import { handleLoraMessage, formatLoraMessage } from "../../loraDecrypt";

const ManualUplink = memo(({ sensorDecoder }) => {

    const notificationCtx = useContext(NotificationContext);
    const [payload, setPayload] = useState("");
    const [netKey, setNetKey] = useState("");
    const [appKey, setAppKey] = useState("");
    const [port, setPort] = useState("");

    const [loraEncrypted, setLoraEncrypted] = useState(true);
    const [isHex, setIsHex] = useState(true);

    const uplinks = [
        { id: 1, text: "App" },
        { id: 2, text: "LoRa MAC" }
    ];

    const [activeUpId, setActiveUpId] = useState(1);
    const [appMessage, setAppMessage] = useState(null);
    const [loraMessage, setLoraMessage] = useState(null);
    const [appWarnMessage, setAppWarnMessage] = useState(null);
    const [loraWarnMessage, setLoraWarnMessage] = useState(null);

    useEffect(() => {
        reset();
    }, [sensorDecoder])

    const handleLora = event => {
        reset();
        setLoraEncrypted(event.target.checked);
        setActiveUpId(1);
    };

    const reset = () => {
        setAppMessage(null);
        setAppWarnMessage(null);
        setLoraMessage(null);
        setLoraWarnMessage(null);
    }

    const handleType = event => setIsHex(!event.target.checked);
    const handleTab = (event, newValue) => setActiveUpId(newValue);

    const tryToFindFcntMSB = (packet, nwkSKey, appKey) => {
        let msb = new Buffer(2);
        let found = false;
        for (let i = 0; i <= 0xFFFF; i++) {
            msb.writeUInt16LE(i, 0);
            if (lora.verifyMIC(packet, nwkSKey, appKey, msb)) {
                found = true;
                break;
            }
        }
        return found ? msb : null;
    }

    function decode() {
        reset();
        try {
            let data = ""
            if (isHex) {
                data = (checkvalidHex(payload))
                if (data === "") {
                    notificationCtx.error("Invalid hex payload")
                    return
                }
            } else {
                data = (checkvalidBase64(payload))
                if (data === "") {
                    notificationCtx.error("Invalid base64 payload")
                    return
                }
            }
            let decodeFileName = sensorDecoder ? sensorDecoder : "/_default-decoder"
            let decodeFile = require("../../sensordecoders" + decodeFileName);

            if (loraEncrypted) {
                let input = payload;
                if (isHex) {
                    input = input.replace(/\s/g, '');
                    input = input.replace(/,/g, '');
                    input = Buffer.from(input, 'hex').toString('base64')
                }
                let payloadData = lora.fromWire(Buffer.from(input, 'base64'))

                let networkSKey = netKey ? Buffer.from(netKey, "hex") : undefined;
                let applicationSKey = appKey ? Buffer.from(appKey, "hex") : undefined;
                let msb;
                let decoded;
                if (payloadData.getMType() === "Join Accept") {
                    setLoraMessage("Cannot properly encrypt Join-Accept without Application Key!");
                }
                else if (payloadData.getMType() === "Join Request") {
                    if (!networkSKey && applicationSKey) {
                        msb = tryToFindFcntMSB(payloadData, null, applicationSKey);
                        decoded = handleLoraMessage(payloadData, null, applicationSKey, msb, true);
                        setLoraWarnMessage(null);
                    } else {
                        decoded = payloadData.toString();
                        setLoraWarnMessage('To validate MIC specify the AppKey in the field of AppSKey and leave NwkSKey empty.');
                    }
                    setLoraMessage(formatLoraMessage(decoded));
                }
                else {
                    if (networkSKey) {
                        msb = tryToFindFcntMSB(payloadData, networkSKey, null);
                        decoded = handleLoraMessage(payloadData, networkSKey, null, msb, true);
                        setLoraWarnMessage(null);
                    }
                    else {
                        decoded = payloadData.toString();
                        setLoraWarnMessage('Provide Network Session Key to validate MIC.');
                    }
                    setLoraMessage(formatLoraMessage(decoded));
                }

                let inputDC;
                let port = payloadData.FPort?.[0];
                if (payloadData.FRMPayload) {
                    if ((port === 0 && networkSKey) || (port !== 0 && applicationSKey)) {
                        let decrypted = lora.decrypt(payloadData, applicationSKey, networkSKey, msb)
                        inputDC = {
                            bytes: decrypted,
                            fPort: port
                        }
                        setAppMessage(JSON.stringify(decodeFile.decodeUplink(inputDC), undefined, 4))
                        setAppWarnMessage(null)
                    }
                    else {
                        inputDC = {
                            FRMPayload: JSON.stringify(convertToUint8Array(payloadData.FRMPayload)),
                            fPort: port
                        }
                        setAppMessage(JSON.stringify(inputDC, undefined, 4))
                        setAppWarnMessage("To see decrypted payload please specify session keys: \n    NwkSKey for fPort = 0 or AppSKey for fPort = 1..255.")
                    }
                }
                else setAppMessage("This packet doesn't have FRMPayload to decode.")
            }
            else {
                let bytes = hexStringToBytes(data);
                let inputDC = {
                    bytes: bytes,
                    fPort: parseInt(port)
                }
                setAppMessage(JSON.stringify(decodeFile.decodeUplink(inputDC), undefined, 4))
                setLoraMessage(null);
            }
        } catch (err) {
            console.warn(err)
            setAppWarnMessage(null);
            setLoraWarnMessage(null);
            notificationCtx.error(err.message)
        }
    }

    return (
        <Box className="d-flex">
            <CustomCard style={{ width: "48%" }}>
                <CardHeader title="Packet Decoder" />
                <CardContent className="d-flex-column">
                    <FormControl fullWidth>
                        <CustomField
                            label="Payload"
                            type="text"
                            value={payload}
                            onChange={e => setPayload(e.target.value)}
                            fullWidth
                        />
                    </FormControl>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        mb: 2
                    }}>
                        <FormControl>
                            <Grid component="label" container alignItems="center" spacing={0}>
                                <Grid item>Hex</Grid>
                                <Grid item>
                                    <Switch checked={!isHex} onChange={handleType} value="checked" />
                                </Grid>
                                <Grid item>Base64</Grid>
                            </Grid>
                        </FormControl>

                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox checked={loraEncrypted} onChange={handleLora} />}
                                label="LoRa-Encrypted" />
                        </FormControl>
                    </Box>

                    {loraEncrypted
                        ? <Box>
                            <FormControl fullWidth>
                                <CustomField
                                    label="Network Session Key"
                                    type="text"
                                    value={netKey}
                                    onChange={e => setNetKey(e.target.value)}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <CustomField
                                    label="Application Session Key"
                                    type="text"
                                    value={appKey}
                                    onChange={e => setAppKey(e.target.value)}
                                    fullWidth
                                />
                            </FormControl>
                        </Box>
                        : <FormControl fullWidth>
                            <CustomField
                                label="Port"
                                type="text"
                                value={port}
                                onChange={e => setPort(e.target.value)}
                                fullWidth
                            />
                        </FormControl>}
                    <Box className="d-flex-center" sx={{
                        width: "100%",
                        marginTop: "auto",
                        marginBottom: "30px",
                    }}>
                        <CustomButton onClick={decode} variant="contained" disabled={!payload || (!loraEncrypted && !port)}>Decode</CustomButton>
                    </Box>
                </CardContent>
            </CustomCard>
            <CustomCard style={{ width: "50%" }}>
                <CardMedia>
                    <Tabs value={activeUpId} onChange={handleTab}>
                        {loraEncrypted
                            ? uplinks.map(el => <Tab label={el.text} key={el.id} value={el.id} />)
                            : <Tab label={uplinks[0].text} key={uplinks[0].id} value={uplinks[0].id} />
                        }
                    </Tabs>
                </CardMedia>
                <CardContent>
                    <CodeBox className="box" note={activeUpId === 1 ? appMessage && appWarnMessage : loraMessage && loraWarnMessage || ""}>
                        {activeUpId === 1
                            ? appMessage || "No payload to decode."
                            : activeUpId === 2
                                ? loraMessage || "No LoRa MAC payload to decode."
                                : <p>No payload to decode.</p>
                        }
                    </CodeBox>
                </CardContent>
            </CustomCard>
        </Box>
    )
})

export default ManualUplink;