import React from "react";
import { CardContent, Box, Typography, Tooltip, Link } from '@mui/material';
import { StaticticCard } from "../common/StyledComponents";
import { STATISTIC_MIN_LIMIT } from "../../constants/types.constant";
import InfoIcon from '@mui/icons-material/Info';

const StatisticCard = ({ title, valueSinceJoin, valueRecentLimit, info, seeDetails, isJoin, error }) => {
    return (
        <StaticticCard>
            <CardContent>
                <Box className="d-flex" justifyContent="space-between">
                    <Box className="d-flex" justifyContent="flex-start" alignItems="start" sx={{ mb: 1.5 }}>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                            {title}
                        </Typography>
                        {info &&
                            <Tooltip title={info} placement="top">
                                <InfoIcon color="primary" sx={{ ml: 1.5 }} />
                            </Tooltip>
                        }
                    </Box>
                    {!error && seeDetails && <Link onClick={seeDetails}>See details</Link>}
                </Box>
                {error
                    ? <Typography variant="subtitle1">{error}</Typography>
                    : <Box className="d-flex" sx={{ alignItems: "end" }}>
                        <Box>
                            <Typography variant="h4" component="div">
                                {valueSinceJoin !== undefined ? valueSinceJoin : "-"}
                            </Typography>
                            <Typography variant="subtitle2">
                                {isJoin ? "since join" : "since the last packet"}
                            </Typography>
                        </Box>
                        {valueRecentLimit !== undefined
                            ? <Box>
                                <Typography variant="h4" component="div">
                                    {valueRecentLimit}
                                </Typography>
                                <Typography variant="subtitle2">
                                    the most recent {STATISTIC_MIN_LIMIT} ULs
                                </Typography>
                            </Box>
                            : null}
                    </Box>
                }
            </CardContent>
        </StaticticCard>
    )
}

export default StatisticCard;