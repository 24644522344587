import React from 'react'
import { FormControl, Grid, Switch, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const ReadWrite = ({ option1, option2, disabled, isChecked, onChange }) => {

  const handleChange = event => {
    onChange(event.target.checked)
  }

  return (
    (option1 && option2) ?
      <FormControl sx={{ height: "42px" }}>
        <Grid component="label" container alignItems="center" >
          <Grid item>{option1}</Grid>
          <Grid item>
            <Switch checked={isChecked} onChange={handleChange} disabled={disabled} />
          </Grid>
          <Grid item>{option2}</Grid>
        </Grid>
      </FormControl >
      : <RadioGroup value="">
        <FormControlLabel value="" checked={true} control={<Radio />} disabled={disabled} label={option1 || option2} />
      </RadioGroup>
  )
}

export default ReadWrite;
