import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NotificationContext from '../../context/NotificationContext';
import AuthContext, { STATES } from '../../context/AuthContext';

const PrivateRoute = ({ redirectTo, errorMessage }) => {

    const notificationCtx = useContext(NotificationContext);
    const AuthCtx = useContext(AuthContext)
    const navigate = useNavigate();
    const isAllowed = AuthCtx.state === STATES.AUTHORIZED;

    useEffect(() => {
        if (!isAllowed) {
            notificationCtx.error(errorMessage);
            return navigate(redirectTo);
        }
    }, [])

    return isAllowed && <Outlet />;
};

export default PrivateRoute;
