import React from "react";
import { useState, useEffect } from "react";
import ParameterInput from "./ParameterInput";
import ReadWrite from "./ParameterRWButton";
import { TableRow, TableCell, Checkbox, Typography, Collapse, Table, TableBody, TableContainer, Box, Tooltip, IconButton } from '@mui/material';
import Info from "../../images/info.png"
import InfoDisabled from "../../images/info-disabled.png"
import ExpandMore from "../../images/expand-more.png"
import ExpandLess from "../../images/expand-less.png"

const ParameterDescription = (({ groupData, onChange, valueObject = {}, openAll, setOpenAll }) => {
    const groupName = groupData[0]["group_name"];
    const isInGroup = groupName !== "";
    let groupOrParameterName = groupName || groupData[0]["parameter_name"];

    const [isLineActive, setIsLineActive] = useState(Boolean(valueObject?.[groupOrParameterName]))

    const onlyWrite = (groupData[0]["access"]).includes("R") ? false : true;
    const [isWrite, setisWrite] = useState(valueObject?.[groupOrParameterName]?.["write"] !== undefined)

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        openAll && setIsOpen(true);
    }, [openAll])

    useEffect(() => {
        setIsLineActive(Boolean(valueObject?.[groupOrParameterName]));
        setisWrite(valueObject?.[groupOrParameterName]?.["write"] !== undefined);
    }, [valueObject])

    const setDefaultValue = (valObject, isRead, parameterName) => {
        if (isRead) {
            valObject[groupName || parameterName] = { "read": true }
        }
        else {
            if (!isInGroup) {
                valObject[parameterName] = { "write": "" }
            } else {
                let newGroupObject = {}
                groupData.map(data => newGroupObject[data["parameter_name"]] = "")
                valObject[groupName] = { "write": newGroupObject }
            }
        }
    }

    const handleRW = (element, value) => {
        setisWrite(value);
        const parameter = element["parameter_name"];
        const newObject = { ...valueObject };
        setDefaultValue(newObject, !value, parameter)
        onChange(newObject);
    }


    const handleActiveLineChange = (element, value) => {
        const parameter = element["parameter_name"];
        setIsLineActive(value);
        setisWrite(onlyWrite);
        const newObject = { ...valueObject };

        if (value) {
            setDefaultValue(newObject, !onlyWrite, parameter)
            setIsOpen(true)
        }
        else {
            delete newObject[groupName || parameter]
        }
        onChange(newObject)
    }

    const handleInput = (element, value) => {
        let parameter = element["parameter_name"];
        const newObject = { ...valueObject };
        if (!isInGroup) {
            newObject[parameter] = { "write": value };
        } else {
            let oldGroup = newObject[groupName]["write"];
            oldGroup[parameter] = value;
            newObject[groupName]["write"] = oldGroup;
        }
        onChange(newObject)
    }

    const handleExpand = () => {
        isOpen && setOpenAll(false)
        setIsOpen(!isOpen)
    }

    const printRow = (el) => {
        let parameter = el["parameter_name"];
        let value;
        if (valueObject) {
            if (!isInGroup)
                value = valueObject[parameter]?.["write"]
            else
                value = valueObject[groupName]?.["write"]?.[parameter]
        }
        return (
            <TableRow key={parameter}>
                {isInGroup ? "" :
                    <TableCell>
                        <Checkbox checked={isLineActive} onChange={(e, v) => handleActiveLineChange(el, v)} />
                    </TableCell>
                }
                <TableCell width={isInGroup ? "39%" : "auto"}>{el["parameter_description"]}</TableCell>
                <TableCell width={isInGroup ? "22%" : "auto"}>
                    {isInGroup ? "" : <ReadWrite option1={el.access[0]} option2={el.access[1]} isChecked={isWrite} disabled={!isLineActive} onChange={(val) => handleRW(el, val)} />}
                </TableCell>
                <TableCell width={isInGroup ? "39%" : "auto"}>
                    <Box className="d-flex">
                        <ParameterInput element={el} onChange={handleInput} isDisabled={!isLineActive || !isWrite} oldValue={value} />
                        <Box width="30px">
                            {el.info &&
                                <Tooltip title={el.info}>
                                    <Box component="img" src={isLineActive && isWrite ? Info : InfoDisabled} className="tooltip-icon" />
                                </Tooltip>}
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        )
    }

    const printGroup = (groupData) => {
        let element = groupData[0];
        return (
            <>
                <TableRow key={element["group_name"] + "_header"}>
                    <TableCell rowSpan={2} sx={{ verticalAlign: "top", paddingTop: 1 }}>
                        <Checkbox checked={isLineActive} onChange={(e, v) => handleActiveLineChange(element, v)} />
                    </TableCell>
                    <TableCell>
                        <Typography variant="title3">{element["group_description"]}</Typography>
                    </TableCell>
                    <TableCell>
                        <ReadWrite option1={element.access[0]} option2={element.access[1]} isChecked={isWrite} disabled={!isLineActive} onChange={(val) => handleRW(groupData[0], val)} />
                    </TableCell>
                    <TableCell sx={{ position: "relative" }}>
                        <IconButton className="expand-icon" onClick={handleExpand} color="primary">
                            <Box component="img" src={isOpen ? ExpandLess : ExpandMore} />
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow key={element["group_name"] + "_body"}>
                    <TableCell className="collapse-cell" colSpan={3}>
                        <Collapse in={isOpen} timeout='auto' unmountOnExit sx={{ width: "100%" }}>
                            <TableContainer>
                                <Table >
                                    <TableBody>
                                        {groupData.map((data) => { return printRow(data) })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    return isInGroup ? printGroup(groupData) : printRow(groupData[0])
})

export default ParameterDescription; 
