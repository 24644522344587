import { styled } from "@mui/material/styles"
import { TextField, Select, Autocomplete, Button, Card, Table } from '@mui/material';

export const CustomButton = styled(Button)
    ({
        borderRadius: "25px",
        fontWeight: "bold",
        padding: "10px 35px"
    });

export const CustomSelect = styled(Select)
    ({
        margin: 0,
        marginBottom: "15px",
          
        "& fieldset": {
            borderRadius: "10px",
        }
    })

export const CustomAutocomplete = styled(Autocomplete)
    ({
        margin: 0,
          
        "& fieldset": {
            borderRadius: "10px",
        }
    })


export const CustomField = styled(TextField)
    ({
        "& .MuiOutlinedInput-root": {
            margin: 0,
            marginBottom: "15px",
            "& input": {
                borderRadius: "10px",
                paddingLeft: "20px",
                backgroundColor: "inherit",
            },
            "& fieldset": {
                borderRadius: "10px",
                paddingLeft: "10px"
            }
        }
    });


export const CustomCard = styled(Card)(
    ({ theme }) => ({
        height: "calc(100vh - 120px)",
        boxShadow: "0px 5px 15px rgba(185, 185, 202, 0.5)",
        "& .MuiCardMedia-root": {
            padding: "0 16px"
        },
        "& .MuiCardHeader-root": {
            backgroundColor: theme.palette.background.secondary,
            textAlign: "center",

            "& .MuiCardHeader-title": {
                textTransform: "uppercase",
                fontSize: "1rem",
            }
        },
        "& .MuiCardContent-root": {
            height: "calc(100vh - 170px)",
            position: "relative"
        }
    }));

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        boxSizing: "border-box",
        height: `calc(100vh - 80px)`,
        flexGrow: 1,
        padding: theme.spacing(3),

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: "30px",
        marginTop: 70,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: theme.spacing(50),
            [theme.breakpoints.down('xl')]: {marginLeft: theme.spacing(40)},
        }),
    }),
);


export const CustomTable = styled(Table)(({ theme }) => ({
    "& th": {
        backgroundColor: theme.palette.background.default,
        fontSize: theme.typography.fontsize
    },
    "& td": {
        padding: "14px",
        borderBottom: "1px solid",
        borderColor: theme.palette.background.default
    },
    "& tr.selected": {
        backgroundColor: theme.palette.background.default,
    }
}))

export const StaticticCard = styled(Card)(
    ({ theme }) => ({
        height: "150px",
        width: "375px",
        border: "2px solid",
        borderColor: theme.palette.background.secondary,
        borderRadius: "30px",
        padding: "5px",
        boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
        "& .MuiCardMedia-root": {
            padding: "0 16px"
        },
        "& .MuiCardHeader-root": {
            backgroundColor: theme.palette.background.secondary,
            textAlign: "center",
            "& .MuiCardHeader-title": {
                textTransform: "uppercase",
                fontSize: "1rem",
            }
        },
        "& .MuiCardContent-root": {
            height: "calc(100vh - 170px)",
            position: "relative"
        }
    }));