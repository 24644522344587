import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from "../Header/Header";
import Notification from '../Notification';

const MainLayout = ({ logBtn }) => {
    return (
        <div>
            <Header logButton={logBtn} />
            <Notification />
            <Outlet />
        </div>
    )
}

export default MainLayout;
