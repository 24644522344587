export const UPLIINK_DATA_RATE_TABLE = {
    "US_902_928": {
        "SF10": {
            "125": 0,
        },
        "SF9": {
            "125": 1,
        },
        "SF8": {
            "125": 2,
            "500": 4
        },
        "SF7": {
            "125": 3,
        }
    },
    "EU_863_870": {
        "SF12": {
            "125": 0,
        },
        "SF11": {
            "125": 1,
        },
        "SF10": {
            "125": 2,
        },
        "SF9": {
            "125": 3,
        },
        "SF8": {
            "125": 4,
        },
        "SF7": {
            "125": 5,
            "250": 6,
        }
    },
    "CN_779_787": {},
    "EU_433": {},
    "AU_915_92": {},
    "CN_470_510": {},
    // "EU_863_870": {},
    // "EU_863_870": {},
    // "EU_863_870": {},
    // "EU_863_870": {},
    // "EU_863_870": {},
}

export const REGION_FREQUENCIES_MAP = {
    "EU_863_870": [
        "868.10", "868.30", "868.50", "867.10"
    ],
    "US_902_928": [
        "902.30", "902.50", "902.70", "902.90", "903.10", "903.30", "903.50", "903.70", "903.90",
        "904.10", "904.30", "904.50", "904.70", "904.90", "905.10", "905.30", "905.50", "905.70",
        "905.90", "906.10", "906.30", "906.50", "906.70", "906.90", "907.10", "907.30", "907.50",
        "907.70", "907.90", "908.10", "908.30", "908.50", "908.70", "908.90", "909.10", "909.30",
        "909.50", "909.70", "909.90", "910.10", "910.30", "910.50", "910.70", "910.90", "911.10",
        "911.30", "911.50", "911.70", "911.90", "912.10", "912.30", "912.50", "912.70", "912.90",
        "913.10", "913.30", "913.50", "913.70", "913.90", "914.10", "914.30", "914.50", "914.70",
        "914.90", "903.00", "904.60", "906.20", "907.80", "909.40", "911.00", "912.60", "914.20",
        "923.30", "923.90", "924.50", "925.10", "925.70", "926.30", "926.90", "927.50"
    ],
    "CN_779_787": [
        "779.5", "779.7", "779.9"
    ],
    "EU_433": [
        "433.175", "433.375", "433.575"
    ],
    "AU_915_928": [
        "915.20", "915.40", "915.60", "915.80", "916.00", "916.20", "916.40", "916.60", "916.80",
        "917.00", "917.20", "917.40", "917.60", "917.80", "918.00", "918.20", "918.40", "918.60",
        "918.80", "919.00", "919.20", "919.40", "919.60", "919.80", "920.00", "920.20", "920.40",
        "920.60", "920.80", "921.00", "921.20", "921.40", "921.60", "921.80", "922.00", "922.20",
        "922.40", "922.60", "922.80", "923.00", "923.20", "923.40", "923.60", "923.80", "924.00",
        "924.20", "924.40", "924.60", "924.80", "925.00", "925.20", "925.40", "925.60", "925.80",
        "926.00", "926.20", "926.40", "926.60", "926.80", "927.00", "927.20", "927.40", "927.60",
        "927.80", "915.90", "917.50", "919.10", "920.70", "922.30", "923.90", "925.50", "927.10",
        "923.30", "923.90", "924.50", "925.10", "925.70", "926.30", "926.90", "927.50"
    ],
    "CN_470_510": [
        "470.30", "470.50", "470.70", "470.90", "471.10", "471.30", "471.50", "471.70", "471.90",
        "472.10", "472.30", "472.50", "472.70", "472.90", "473.10", "473.30", "473.50", "473.70",
        "473.90", "474.10", "474.30", "474.50", "474.70", "474.90", "475.10", "475.30", "475.50",
        "475.70", "475.90", "476.10", "476.30", "476.50", "483.90", "484.10", "484.30", "484.50",
        "484.70", "484.90", "485.10", "485.30", "485.50", "485.70", "485.90", "486.10", "486.30",
        "486.50", "486.70", "486.90", "487.10", "487.30", "487.50", "487.70", "487.90", "488.10",
        "488.30", "488.50", "488.70", "488.90", "489.10", "489.30", "489.50", "489.70", "489.90",
        "490.10", "490.30", "490.50", "490.70", "490.90", "491.10", "491.30", "491.50", "491.70",
        "491.90", "492.10", "492.30", "492.50", "492.70", "492.90", "493.10", "493.30", "493.50",
        "493.70", "493.90", "494.10", "494.30", "494.50", "494.70", "494.90", "495.10", "495.30",
        "495.50", "495.70", "495.90", "496.10", "496.30", "496.50", "503.50", "503.70", "503.90",
        "504.10", "504.30", "504.50", "504.70", "504.90", "505.10", "505.30", "505.50", "505.70",
        "505.90", "506.10", "506.30", "506.50", "506.70", "506.90", "507.10", "507.30", "507.50",
        "507.70", "507.90", "508.10", "508.30", "508.50", "508.70", "508.90", "509.10", "509.30",
        "509.50", "509.70", "476.90", "477.10", "477.30", "477.50", "477.70", "477.90", "478.10",
        "478.30", "478.50", "478.70", "478.90", "479.10", "479.30", "479.50", "479.70", "479.90",
        "480.10", "480.30", "480.50", "480.70", "480.90", "481.10", "481.30", "481.50", "481.70",
        "481.90", "482.10", "482.30", "482.50", "482.70", "482.90", "483.10", "496.90", "497.10",
        "497.30", "497.50", "497.70", "497.90", "498.10", "498.30", "498.50", "498.70", "498.90",
        "499.10", "499.30", "499.50", "499.70", "499.90", "500.10", "500.30", "500.50", "500.70",
        "500.90", "501.10", "501.30", "501.50", "501.70", "501.90", "502.10", "502.30", "502.50",
        "502.70", "502.90", "503.10", "476.70", "483.30", "483.50", "483.70", "503.30"
    ],
    "AS_923": [

    ],
    "KR_920_923": [
        "920.90", "921.10", "921.30", "921.50", "921.70", "921.90", "922.10", "922.30", "922.50", "922.70",
        "922.90", "923.10", "923.30"
    ],
    "IN_865_867": [
        "865.0625", "865.4025", "865.985"
    ],
    "RU_864_870": [
        "868.90", "869.10"
    ]
}

export const findRegionByPacketFrequency = (freq) => {
    let MFreq = freq < 1000000 ? freq : freq / 1000000;
    MFreq = MFreq.toFixed(2).toString();
    return Object.keys(REGION_FREQUENCIES_MAP).find(key => REGION_FREQUENCIES_MAP[key].includes(MFreq));
}