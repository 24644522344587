import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { AppBar, Toolbar, Button, Typography } from '@mui/material';

export default function Header({ logButton }) {
    const { userInfo } = useContext(AuthContext);
    const navigate = useNavigate();
    const goToSignIn = () => navigate('/sign-in');

    return (
        <AppBar color="primary" sx={{ zIndex: 1250 }} >
            <Toolbar className="d-flex">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <Typography variant="logo" className="logo">
                        KONA ATLAS
                    </Typography>
                </Link>
                <div className="d-flex-center">
                    {userInfo && <p className="user-name">{userInfo.userName}</p>}
                    {logButton &&
                        <Button sx={{ color: "white", borderRadius: "20px", padding: "7px 30px", backgroundColor: "#2CACE2" }}
                            onClick={goToSignIn}>
                            {logButton}
                        </Button>
                    }
                </div>
            </Toolbar>
        </AppBar>
    );
}
