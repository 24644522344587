const sensormodels = require.context('../../sensormodels',true,/.json$/);
const sensordecoders = require.context('../../sensordecoders',true,/.js$/);
const sensorencoders = require.context('../../sensorencoders',true,/.js$/);
export function getSensorModels() {
    try {
        var sensors = [];
        sensormodels.keys().forEach(key => {
            let model = key.replaceAll(".json", "");
            model = model.replace("./", "");
            model = model.replaceAll("-", " ");
            key = key.replaceAll("./", "/");
            sensors.push({
                sensor: model,
                model: key
            });
        });
        sensordecoders.keys().forEach(key => {
            let model = key.replaceAll(".js", "");
            model = model.replace("./", "");
            model = model.replace("Uplink", "");
            model = model.replace(".min", "");
            model = model.replaceAll("-", " ");
            key = key.replaceAll("./", "/");
            sensors.findIndex(sensor => {
                if (sensor.sensor === model) {
                    sensor.decoder = key;
                }
            });
        });
        sensorencoders.keys().forEach(key => {
            let model = key.replaceAll(".js", "");
            model = model.replace("./", "");
            model = model.replace("Downlink", "");
            model = model.replace(".min", "");
            model = model.replaceAll("-", " ");
            key = key.replaceAll("./", "/");
            sensors.findIndex(sensor => {
                if (sensor.sensor === model) {
                    sensor.encoder = key;
                }
            });
        });
        return sensors;
    } catch (error) {
        console.warn(error);
        return null;
    }
}