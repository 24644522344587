import { createContext } from 'react';

export const STATES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const NotificationContext = createContext({
  notification: null,
  notificationText: null,
  success: () => { },
  error: () => { },
});

export default NotificationContext;